import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/Layout'
import { YellowButton } from '../../components/Global/Button'
import LeftWithImage from '../../components/Global/Hero/LeftWithImage'
import { InlineLink } from '../../components/Global/Link'
import SEO from '../../components/Global/SEO'
import SectionTitle from '../../components/Global/SectionTitle'
import { GiantsCauseway, CarrickfergusCastle, AntrimCoastAndGlens, CarrickARedeRopeBridge, TheDarkHedges, Bushmills } from '../../components/Tours/Locations'
import { ContentWrapper, InfoWithImageLeft, InfoWithImageRight, SVGPattern } from '../../components/Global/Content'
import { OffsetGridWrapper } from '../../components/Global/Content/OffsetGrid'

const CausewayCoastTour = ({ location }) => {
  
  const { seoImg, causeway, giantscauseway, antrimcoast } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "giants-causeway.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
      causeway: file(relativePath: { eq: "giants-causeway.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900, maxHeight: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      giantscauseway: file(relativePath: { eq: "giants-causeway-2.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 980, maxHeight: 1140) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      antrimcoast: file(relativePath: { eq: "antrim-coast.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 980, maxHeight: 1140) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>

      <SEO
        title="Giant's Causeway Tour | Giant's Causeway, Antrim Coast, Bushmills"
        description="The spectacular drive along Antrim's Causeway Coast is one of the world's finest, many attractions lie on the route including the famous Giant's Causeway."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />

      <LeftWithImage
        image={causeway.childImageSharp.fluid}
        imageAlt="Giant's Causeway"
      >
        <div className="text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          Private Tour
        </div>
        <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
          Giant's Causeway
          <br className="hidden md:inline" />
          <span className="text-yellow-300">Tour</span>
        </h2>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          The spectacular drive along Antrim's Causeway Coast is one of the world's finest, many attractions lie on the route including the famous Giant's Causeway.
        </p>
        <YellowButton to="/book-now" className="inline-block mt-8">Book Now</YellowButton>
      </LeftWithImage>

      <ContentWrapper>

        <SectionTitle
          title="The Perfect One Day Tour"
          text="Tour in comfort with cost effective sightseeing of the magnificent North Coast, including the famous Giant's Causeway, in one of Chauffeur Me's luxury fleet of vehicles."
        />

        <InfoWithImageRight
          image={giantscauseway.childImageSharp.fluid}
          imageAlt="Sunset over the famous basalt columns at the Giant's Causeway."
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            Discover the Giant's Causeway
          </h4>
          <p className="mt-3 text-lg leading-7 font-semibold text-gray-500">
            Join us on our Giant's Causeway Tour as we travel to what is often referred to as 'the eighth wonder of the world'.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Visit the spectacular Antrim Coast and discover the key attractions it has to offer as our tour takes you on one of the world’s most spectacular drives!
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            Enjoy the comfort of our luxury fleet of vehicles as you revel in the knowledge of our friendly and experienced drivers who are there for you every step of the way.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            The Giant's Causeway, Ireland's only World Heritage site is, without doubt, the highlight of the tour. Enjoy the stories of Finn MacCool that surround this world famous attraction as you climb over the ancient basalt columns and take photographs to remember.
          </p>
        </InfoWithImageRight>

        <SVGPattern />

        <InfoWithImageLeft
          image={antrimcoast.childImageSharp.fluid}
          imageAlt="Picturesque view of the Antrim Coast on a warm summers day."
        >
          <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
            See More with Chauffeur Me
          </h4>
          <p className="mt-3 text-lg font-semibold leading-7 text-gray-500">
            Visit six prime locations on Ireland’s northern coastline as we make this a tour that is not to be missed.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            En route to the Giant's Causeway we visit Carrickfergus Castle, travel through the marvellous Antrim Coast and Glens Area of Natural Beauty and have a photo stop overlooking Carrick-a-Rede Rope Bridge.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
              Our driver will also stop you off for lunch in the idyllic village of Bushmills home to the Old Bushmills Distillery. Next up is our main stop at the Giant's Causeway, before a final photo stop at the Dark Hedges, made popular by hit TV series Game of Thrones, on our homeward journey.
          </p>
          <p className="mt-3 text-base leading-7 text-gray-500">
            So what are you waiting for? The Giant's Causeway Tour is available to <InlineLink to="/book-now">book now</InlineLink>.
          </p>
        </InfoWithImageLeft>
        
      </ContentWrapper>

      <OffsetGridWrapper title="Giant's Causeway Tour Locations">
        <GiantsCauseway />
        <CarrickfergusCastle />
        <AntrimCoastAndGlens />
        <CarrickARedeRopeBridge />
        <TheDarkHedges />
        <Bushmills />
      </OffsetGridWrapper>

    </Layout>
  )
}

export default CausewayCoastTour